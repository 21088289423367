<script>
import { Router } from '@sveltech/routify'
import { routes } from '@sveltech/routify/tmp/routes'
import { authStore } from './auth.js'

// we need to queue our init till after Routify has been initialized
setTimeout(() => window.routify.inBrowser && authStore.init())
</script>

<style global>/* BODY */
:global(body) {
	padding: 0;
	margin: 0;
}
/* APP */
:global(.app) {
	display: grid;
	grid-template-rows: auto 1fr auto;
	min-height: 100vh;
}
:global(.app) > :global(main) {
	background-color: #ffaa00;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23ffb100' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23ffb800' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23ffbf00' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23ffc500' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23ffcc00' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23ffd624' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23ffe038' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23ffeb49' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23fff558' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23ffff66' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
	background-attachment: fixed;
	background-size: cover;
}
/* NAVIGATION */
:global(nav) {
	display: grid;
	grid-template-columns: 1fr minmax(max-content, 1fr) 1fr;
	justify-content: space-evenly;
}
:global(nav) :global(:last-child) {
	text-align: right;
}
:global(nav) :global(:nth-child(2)) {
	text-align: center;
}
:global(nav) > :global(*) {
	white-space: nowrap;
}
:global(nav) :global(a),
:global(nav) :global(a:visited) {
	display: inline-block;
	text-transform: uppercase;
	color: #777;
	padding: 24px;
	padding-left: calc(2vw);
	padding-right: calc(2vw);
}
:global(nav) :global(img[alt^='profile - ']) {
	border-radius: 100%;
	width: 32px;
	vertical-align: middle;
}
/* FOOTER */
:global(footer) {
	position: absolute;
	bottom: 8px;
	right: 16px;
}
/* HEADER */
:global(header) {
	box-shadow: 0 4px 8px rgba(0, 0s, 0, 0.5);
	border-bottom: 1px solid #ccc;
}
/* ADMIN */
:global(.admin-module) {
	min-height: 100%;
	display: grid;
	background-color: #ff7700;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%23cc0000' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%23aa0000' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%23d6002b' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%23b10022' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%23d9004b' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%23b2003d' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23d3006c' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23ac0057' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%23c4008c' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%239e0071' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%23aa00aa' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%23880088' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
	background-attachment: fixed;
	background-size: cover;
}
:global(.admin-module.not-authed) {
	background-color: #808080;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%23666666' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%23545454' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%23636363' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%23515151' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%23606060' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%234e4e4e' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%235d5d5d' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%234b4b4b' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%235a5a5a' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%23484848' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%23575757' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%23454545' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
}
/* GENERICS */
:global(.card) {
	/* background: rgba(255,255,255, 0.9); */
	background: white;
	padding: 24px 48px;
	border-radius: 4px;
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}
:global(.button) {
	background: #333;
	margin-top: 32px;
	padding: 16px 24px;
	color: white;
	outline: none;
	border: none;
	box-shadow: none;
	font-weight: bold;
}
:global(.center-all) {
	min-height: 100%;
	display: grid;
	justify-content: space-evenly;
	align-content: space-around;
}
:global(code) {
	margin-top: 48px;
	background: #444;
	display: block;
	color: white;
	text-align: center;
	padding: 16px;
}
:global(html), :global(body) {
	position: relative;
	width: 100%;
	height: 100%;
}
:global(body) {
	color: #333;
	margin: 0;
	padding: 8px;
	box-sizing: border-box;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
:global(a) {
	color: rgb(0,100,200);
	text-decoration: none;
}
:global(a:hover) {
	text-decoration: underline;
}
:global(a:visited) {
	color: rgb(0,80,160);
}
:global(label) {
	display: block;
}
:global(input), :global(button), :global(select), :global(textarea) {
	font-family: inherit;
	font-size: inherit;
	padding: 0.4em;
	margin: 0 0 0.5em 0;
	box-sizing: border-box;
	border: 1px solid #ccc;
	border-radius: 2px;
}
:global(input:disabled) {
	color: #ccc;
}
:global(input[type="range"]) {
	height: 0;
}
:global(button) {
	color: #333;
	background-color: #f4f4f4;
	outline: none;
}
:global(button:disabled) {
	color: #999;
}
:global(button:not(:disabled):active) {
	background-color: #ddd;
}
:global(button:focus) {
	border-color: #666;
}</style>

<Router {routes} />
